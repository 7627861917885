import Vue from 'vue'

export default Vue.extend({
  name: 'DynamicPage',
  props: {
    pageData: {
      type: Object,
      required: true
    }
  },
  computed: {
    dynamicPage () {
      console.log('in dynamic page getter')
      const pageClass = this.$xp.http.getPageClass(this.pageData.Class)
      console.log('in dynamic page getter', pageClass)
      // if (pageClass === 'XpHomePage') return
      // if (pageClass === 'XpHomePage') return this.$xp.http.setHomePage(this.pageData)
      return pageClass
    },
    enterActiveClass () {
      if (this.$xp.navigation.entryNavDirection === 'previous') {
        return 'animated fadeInLeftBig'
      } else if (this.$xp.navigation.entryNavDirection === 'next') {
        return 'animated fadeInRightBig'
      } else {
        return 'animated fadeIn'
      }
    },
    leaveActiveClass () {
      if (this.$xp.navigation.entryNavDirection === 'previous') {
        return 'animated fadeOutRightBig'
      } else if (this.$xp.navigation.entryNavDirection === 'next') {
        return 'animated fadeOutLeftBig'
      } else {
        return 'animated fadeOut'
      }
    }
  },
  methods: {
    // getPageClass (pageClass) {
    //   switch (pageClass) {
    //     case 'XpSplashScreen':
    //       return 'XpSplashScreenPage'
    //     case 'XpSelectLanguage':
    //       return 'XpSelectLanguagePage'
    //     case 'XpHome':
    //       return 'XpHomePage'
    //     case 'XpMenu':
    //       return 'XpMenuPage'
    //     case 'XpMap':
    //       return 'XpMapPage'
    //     case 'XpMultipleChoice':
    //       return 'XpMultipleChoicePage'
    //     case 'XpNfc':
    //       return 'XpNfcPage'
    //     case 'XpNumericInput':
    //       return 'XpNumericInputPage'
    //     case 'XpError':
    //       return 'XpErrorPage'
    //     case 'XpFlipbook':
    //       return 'XpFlipbookPage'
    //     default:
    //       return 'XpPage'
    //   }
    // },
    // setHomePage () {
    //   console.log('in setHomePage this.$route.params.id', this.$route.params.id)

    //   if (this.pageData.NfcPageId) {
    //     console.log('in pageMixin, Homepage, set NfcPageID=', this.pageData.NfcPageId)
    //     window.localStorage.setItem('nfcPageId', this.pageData.NfcPageId)
    //   }

    //   this.childrenPageData = JSON.parse(JSON.stringify(this.pageData.children))
    //   if (this.childrenPageData.length > 0) {
    //     // show nav only when tab items > 0
    //     this.$xp.navigation.preventAutoHide()
    //     this.$xp.navigation.showNav()
    //   } else if (this.childrenPageData.length === 1 && this.childrenPageData[0].Class === 'XpSplashScreen') {
    //     // hide navigation (splash screen is used for example)
    //     this.$xp.navigation.preventAutoHide()
    //     this.$xp.navigation.hideNav()
    //   }
    //   // this.$xp.navigation.setTabs(this.childrenPageData)
    //   if (this.childrenPageData.length > 1 && this.$route.query.activeTabId) {
    //     this.$xp.navigation.setActiveTabId(parseInt(this.$route.query.activeTabId))
    //   } else if (this.$xp.history.historyParams[`homeActiveTab${this.$route.params.id}`]) {
    //     this.$xp.navigation.setActiveTabId(parseInt(this.$xp.history.historyParams[`homeActiveTab${this.$route.params.id}`]))
    //   } else if (this.childrenPageData.length && !this.$xp.navigation.activeTab) {
    //     this.$xp.navigation.setActiveTabId(this.childrenPageData[0].ID)
    //   }
    //   console.log(`XpHomePage redirecting to /page/${this.$xp.navigation.activeTabId}`)
    //   let tabClass = ''
    //   this.childrenPageData.every(element => {
    //     if (element.ID === this.$xp.navigation.activeTabId) {
    //       tabClass = this.$xp.http.getPageClass(element.Class)
    //       return false
    //     }
    //     return true
    //   })
    //   console.log(`XpHomePage in DynamicPage redirecting to ${tabClass}`)
    //   this.$xp.history.replace(this.$xp.navigation.activeTabId, `/page/${this.$xp.navigation.activeTabId}`)
    //   this.$router.replace(`/page/${this.$xp.navigation.activeTabId}`)
    //   console.log('in Dynamic page returning value', tabClass)
    //   return tabClass
    // },
    getPageClasses () {
      return {
        'o-page': true,
        'is-padded-for-nav': !this.pageData.isNavOverlapping,
        'is-full-screen': this.$xp.navigation.isFullScreen,
        'is-padded-for-ios-safe-area': (this.$xp.navigation.tabs.length > 0 && this.$xp.screen.orientation === 'landscape-secondary' && this.$xp.device.isIPhoneOrPad)
      }
    },
    renderAudioPlayer (h) {
      // <audio-player
      //   v-eva-media **ONLY IF $xp.evaInstalled
      //   v-if="$xp.navigation.isAudioSynced"
      //   :source="$xp.settings.baseUrl + 'xpedeo/' + pageData.Audio.Url"
      //   :binaryId="pageData.Audio.BinaryID"
      //   :pageId="pageData.ID"
      //   :audioSync="pageData.AudioSync"
      //   :autoPlay="$xp.settings.mergedSettings.navigation.autoPlayPageAudio"
      //   :lipSyncUrl="(pageData.Class === 'XpVideoSync') ? pageData.lipSyncUrl : null"></audio-player>
      return this.$xp.navigation.isAudioSynced
        ? h('audio-player', {
          props: {
            source: this.$xp.settings.baseUrl + 'xpedeo/' + this.pageData.Audio.Url,
            binaryId: this.pageData.Audio.BinaryID,
            pageId: this.pageData.ID,
            audioSync: this.pageData.AudioSync,
            autoPlay: this.$xp.settings.mergedSettings.navigation.autoPlayPageAudio,
            lipSyncUrl: (this.pageData.Class === 'XpVideoSync') ? this.pageData.lipSyncUrl : null,
            loop: this.pageData.LoopAudio
          },
          directives: this.$xp.evaInstalled ? [{ name: 'eva-media' }] : []
        })
        : null
    },
    renderPreloadModal (h) {
      return this.$xp.content.showPreloadModal
        ? h('preload-modal', {
          props: {
            preloadPath: this.$xp.content.preloadPath
          }
        })
        : null
    },
    renderSections (h) {
      // <xp-section v-for="section in pageData.Sections" :key="section.ID">
      //   <transition-group
      //   v-if="$xp.navigation.isFullScreen"
      //   :enter-active-class="enterActiveClass"
      //   :leave-active-class="leaveActiveClass"
      //   :duration="{ enter: 200, leave: 200 }">
      //     <dynamic-entry
      //       v-for="(entry, index) in section.entries"
      //       :key="entry.ID"
      //       v-show="(!$xp.navigation.currentEntry && index === 0) || $xp.navigation.currentEntry === 'entry-' + entry.ID"
      //       :entryData="entry"
      //       :localPageData="pageData"
      //       :id="'entry-' + entry.ID"></dynamic-entry>
      //   </transition-group>
      //   <dynamic-entry v-else v-for="entry in section.entries" :entryData="entry" :localPageData="pageData" :key="entry.ID" :id="'entry-' + entry.ID"></dynamic-entry>
      // </xp-section>
      return this.pageData.Sections.map(section => {
        return h('xp-section', {
          key: section.ID,
          props: { sectionData: section }
        }, [
          this.$xp.navigation.isFullScreen
            ? h(
              'transition',
              { props: { 'enter-active-class': this.enterActiveClass, 'leave-active-class': this.leaveActiveClass, duration: { enter: 200, leave: 200 } } },
              section.entries.map((entry, index) => {
                return (!this.$xp.navigation.currentEntry && index === 0) || this.$xp.navigation.currentEntry === 'entry-' + entry.ID
                  ? h(
                    'dynamic-entry',
                    {
                      key: entry.ID,
                      props: { entryData: entry, localPageData: this.pageData },
                      attrs: { id: 'entry-' + entry.ID }
                    }
                  )
                  : null
              })
            )
            : section.entries.map(entry => {
              return h('dynamic-entry',
                {
                  key: entry.ID,
                  props: { entryData: entry, localPageData: this.pageData },
                  attrs: { id: 'entry-' + entry.ID }
                }
              )
            })
        ])
      })
    },
    renderEntryNav (h) {
      // <a v-if="$xp.navigation.isEntryNavVisible" :class="{'o-page__previous-entry': true, 'is-hidden': $xp.navigation.isNavHidden || !this.$xp.navigation.hasPreviousEntry}" @click.prevent="$xp.navigation.goToPreviousEntry" >
      //   <span class="xp xp-chevron-left xp--large link"></span>
      // </a>
      // <a v-if="$xp.navigation.isEntryNavVisible" :class="{'o-page__next-entry': true, 'is-hidden': $xp.navigation.isNavHidden || !this.$xp.navigation.hasNextEntry}" @click.prevent="$xp.navigation.goToNextEntry" >
      //   <span class="xp xp-chevron-right xp--large link"></span>
      // </a>
      return this.$xp.navigation.isEntryNavVisible || this.$xp.navigation.bManualSlideshow
        ? [
          h('a', {
            class: {
              'o-page__previous-entry': true,
              'is-hidden': (this.$xp.navigation.isNavHidden && !this.$xp.navigation.bManualSlideshow) || !this.$xp.navigation.hasPreviousEntry,
              large: this.$xp.navigation.bManualSlideshow
            },
            attrs: { title: this.$t('global.prevEntryBtnLabel'), tabindex: '0', role: 'link' },
            on: {
              click: () => { this.$xp.navigation.goToPreviousEntry() },
              keyup: (e) => { if (e.key === 'Enter' || e.key === ' ') this.$xp.navigation.goToPreviousEntry() }
            }
          }, [h('span', { class: 'xp xp-chevron-left xp--large link' })]),
          h('a', {
            class: {
              'o-page__next-entry': true,
              'is-hidden': (this.$xp.navigation.isNavHidden && !this.$xp.navigation.bManualSlideshow) || !this.$xp.navigation.hasNextEntry,
              large: this.$xp.navigation.bManualSlideshow
            },
            attrs: { title: this.$t('global.nextEntryBtnLabel'), tabindex: '0', role: 'link' },
            on: {
              click: () => { this.$xp.navigation.goToNextEntry() },
              keyup: (e) => { if (e.key === 'Enter' || e.key === ' ') this.$xp.navigation.goToNextEntry() }
            }
          }, [h('span', { class: 'xp xp-chevron-right xp--large link' })]),
          h('ul', {
            class: {
              'o-page__entry-paging': true,
              'is-hidden': (this.$xp.navigation.isNavHidden && !this.$xp.navigation.bManualSlideshow)
            },
            attrs: {}
          }, [
            this.$xp.navigation.entriesIdList.map((entryId, index) => {
              return h('li', {
                class: {
                  'entry-paging-item': true,
                  'is-active': false
                },
                key: entryId
              }, [
                h('a', {
                  class: {
                    active: this.$xp.navigation.currentEntryIndex === index
                  },
                  attrs: { title: this.$t('global.gotoEntryBtnLabel') + ' ' + (index + 1), tabindex: '0', role: 'link' },
                  on: {
                    click: () => { console.log(entryId); this.$xp.navigation.goToEntry(entryId) },
                    keyup: (e) => { if (e.key === 'Enter' || e.key === ' ') this.$xp.navigation.goToEntry(entryId) }
                  }
                })
              ])
            })
          ])
        ]
        : []
    }
  },
  watch: {
    pageData: {
      handler () {
        if (this.pageData.Class === 'XpSplashScreen') {
          this.$xp.navigation.hideNav()
          this.$xp.navigation.preventAutoHide()
        } else if (this.pageData.isAutoHideNavAllowed || this.pageData.IsFullScreen) {
          this.$xp.navigation.allowAutoHide()
          this.$xp.navigation.showNav(true)
        } else {
          this.$xp.navigation.preventAutoHide()
          this.$xp.navigation.showNav()
        }
      }
    }
  },
  render (h) {
    return this.pageData.ID ? h(
      this.dynamicPage,
      {
        props: {
          localPageData: this.pageData
        },
        class: this.getPageClasses(),
        key: this.pageData.ID
      },
      [
        this.renderAudioPlayer(h),
        ...this.renderSections(h),
        ...this.renderEntryNav(h),
        this.renderPreloadModal(h)
      ]
    ) : null
  }
})
